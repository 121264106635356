var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"what-get -s-m-100",class:{
    'what-get--no-btn': !_vm.btnTitle,
    'swiper-pagination-stop': !_vm.isVisibleSwiper,
  },style:({
    '--btn-width': (_vm.btnWidth + "px"),
  }),attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"what-get__wr"},[_c('div',{staticClass:"container"},[(_vm.blok.title)?_c(_vm.titleHeading,{tag:"component",staticClass:"what-get__title -h2 -ws-pl",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"},domProps:{"innerHTML":_vm._s(_vm.blok.title)}}):_vm._e(),_vm._v(" "),_c('swiper',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.swiperVisibilityChanged,
          intersection: {
            rootMargin: '-86px 0px',
          },
        }),expression:"{\n          callback: swiperVisibilityChanged,\n          intersection: {\n            rootMargin: '-86px 0px',\n          },\n        }"},{name:"click-outside",rawName:"v-click-outside",value:(_vm.handleSwiperStart),expression:"handleSwiperStart"}],ref:"refSwiper",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up","data-aos-delay":"150","options":_vm.options,"cleanup-styles-on-destroy":false},on:{"click":_vm.handleSwiperStop}},[_vm._l((_vm.blok.slides),function(slide){return _c('swiper-slide',{directives:[{name:"editable",rawName:"v-editable",value:(slide),expression:"slide"}],key:slide._uid},[_c('div',{staticClass:"what-get__slide",class:[slide.theme]},[_c('div',{staticClass:"what-get__slide-top"},[_c('div',{staticClass:"what-get__slide-tag -t5-700"},[_vm._v(_vm._s(slide.tag))]),_vm._v(" "),(slide.image && slide.image.filename)?_c('img',{directives:[{name:"lazy-load",rawName:"v-lazy-load"}],attrs:{"data-src":_vm._f("sbImageService")(slide.image.filename),"alt":slide.image.alt,"width":_vm._f("sbImageSize")(slide.image.filename,'width', 430),"height":_vm._f("sbImageSize")(slide.image.filename,'height', 210)}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"what-get__slide-bottom"},[(slide.title)?_c(_vm.slideHeading,{tag:"component",staticClass:"what-get__slide-title -t1 -ws-pl",domProps:{"innerHTML":_vm._s(slide.title)}}):_vm._e(),_vm._v(" "),(slide.description)?_c('p',{staticClass:"what-get__slide-descr -t4-500 -ws-pl",domProps:{"innerHTML":_vm._s(slide.description)}}):_vm._e()],1)])])}),_vm._v(" "),_c('div',{staticClass:"swiper-pagination -g-jc-fe",attrs:{"slot":"pagination"},slot:"pagination"})],2),_vm._v(" "),(_vm.btnTitle)?_c('BaseBtn',{ref:"button",class:[_vm.aosAnimate],attrs:{"data-aos":"fade","data-aos-delay":"300","title":_vm.btnTitle,"link":_vm.blok.btn_link,"iconNext":"arrow"}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }