//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from "lodash/debounce";

export default {
  name: 'BlokHomeWhatYouGet',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
      isVisibleSwiper: false,
      options: {
        loop: false,
        slidesPerView: 'auto',
        watchOverflow: true,
        spaceBetween: 10,
        autoplay: {
          delay: 3000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      destroyed: false,
      btnWidth: 0,
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    titleHeading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
    slideHeading() {
      switch(this.index) {
        case 0:
          return 'h2';
        default:
          return 'h3';
      }
    },
    swiper() {
      return this.$refs.refSwiper.$swiper;
    },
    btnTitle() {
      return this.blok.btn_title;
    },
  },
  watch: {
    btnTitle: {
      handler(v) {
        this.$nextTick(() => {
          this.handleResize();
        });
      }
    },
  },
  methods: {
    handleResize() {
      const el = this.$refs.button?.$el;
      if (this.destroyed || !el) return;

      const btnWidth = el.offsetWidth;
      if (this.btnWidth !== btnWidth) this.btnWidth = btnWidth;
    },
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    swiperVisibilityChanged(isVisible) {
      this.isVisibleSwiper = isVisible;

      if (isVisible) {
        this.swiper.autoplay.start();
      } else {
        this.swiper.autoplay.stop();
      }
    },
    autoplayStart() {
      this.$el.classList.remove('swiper-pagination-pause');
    },
    autoplayStop() {
      this.$el.classList.add('swiper-pagination-pause');
    },
    handleSwiperStop() {
      this.swiper.autoplay.stop();
    },
    handleSwiperStart() {
      if (this.isVisibleSwiper) {
        this.swiper.autoplay.start();
      }
    },
  },
  created() {
    this.debounceHandleResize = debounce(this.handleResize, 150);
  },
  mounted() {
    this.$nextTick(() => {
      this.handleResize();
    });

    this.swiper
      .on('autoplayStart', () => {
        this.autoplayStart();
      })
      .on('autoplayStop', () => {
        this.autoplayStop();
      });

    window.addEventListener('resize', this.debounceHandleResize);
  },
  beforeDestroy() {
    this.destroyed = true;

    window.removeEventListener('resize', this.debounceHandleResize);
  },
}
